import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./styles/SalaryTable.css";

import { currencyFormatter } from "./Utils";

const companyColumnTemplate = (product) => {
  return (
    <div class="flex flex-column">
      <span>{product["company"]}</span>
      <span style={{ fontSize: "10px" }}>
        {product["timestamp"].split(" ")[0]}
      </span>
    </div>
  );
};

const compensationTemplate = (product) => {
  return (
    <div class="flex flex-column">
      <span>{currencyFormatter(product["totalCompensation"])}</span>
      <span style={{ fontSize: "10px" }}>
        {currencyFormatter(product.baseSalary, true)} |{" "}
        {currencyFormatter(product.bonus, false)}
      </span>
    </div>
  );
};

const columns = [
  { field: "company", header: "Company", body: companyColumnTemplate },
  { field: "jobTitle", header: "Job Title" },
  {
    field: "yoe",
    header: "Years of Experience",
    align: "center",
  },
  {
    field: "totalCompensation",
    header: "Total Compensation",
    align: "right",
    body: compensationTemplate,
  },
];

export default function SalaryTable({ data }) {
  return (
    <div class="border-round border-900">
      <DataTable
        value={data}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{
          //   minWidth: "50rem",
          fontSize: "14px",
          border: "1px",
        }}
      >
        <Column
          key={"Company"}
          field={"company"}
          header={"Company"}
          body={companyColumnTemplate}
          headerStyle={{ backgroundColor: "#eae6fd" }}
        />
        <Column
          key={"Job Title"}
          field={"jobTitle"}
          header={"Job Title"}
          headerStyle={{ backgroundColor: "#eae6fd" }}
        />
        <Column
          key={"Years of Experience"}
          field={"yoe"}
          header={"Years of Experience"}
          align={"center"}
          headerStyle={{ backgroundColor: "#eae6fd" }}
        />
        <Column
          key={"Total Compensation"}
          field={"totalCompensation"}
          header={"Total Compensation"}
          body={compensationTemplate}
          align={"right"}
          headerStyle={{ backgroundColor: "#eae6fd" }}
        />

        {/* {columns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.body || null}
            align={col.align || "left"}
            headerStyle={{ backgroundColor: "#eae6fd" }}
          />
        ))} */}
      </DataTable>
    </div>
  );
}
