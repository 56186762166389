export const UrlTojobTitleMapping = {
  "research-associate": "Research Associate",
  "sr-research-associate": "Senior Research Associate",
  "associate-scientist": "Associate Scientist",
  "sr-associate-scientist": "Senior Associate Scientist",
  scientist: "Scientist",
  "sr-scientist": "Senior Scientist",
  director: "Director",
};

export const JobTitleToURLMapping = {
  "Research Associate": "research-associate",
  "Senior Research Associate": "sr-research-associate",
  "Associate Scientist": "associate-scientist",
  "Senior Associate Scientist": "sr-associate-scientist",
  Scientist: "scientist",
  "Senior Scientist": "sr-scientist",
  Director: "director",
};
