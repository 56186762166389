import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// PrimeReact settings
import "primereact/resources/themes/tailwind-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

// Pages
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import JobTitle from "./pages/JobTitle";
import Location from "./pages/Location";
import Navigation from "./components/Navigation";
import { Discipline } from "./pages/Discipline";
import { ContributionForm } from "./components/ContributionForm";

function App() {
  return (
    <div className="App" class="surface-ground">
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/locations" element={<Location />} />
          <Route path="/title">
            <Route path="*" element={<JobTitle />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/discipline" element={<Discipline />} />
          <Route path="/contribute" element={<ContributionForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
