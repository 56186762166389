export const jobTitleLocations = {
  "United States": "US",
  "San Francisco Bay Area": "Bay Area",
  Boston: "Boston",
  "Research Triangle": "Research Triangle",
  California: "CA",
};

export const countries = new Set([
  "Australia",
  "Canada",
  "England",
  "Germany",
  "Ireland",
  "Slovenia",
  "UK",
  "US",
]);

export const cities = new Set([
  "Alachua",
  "Albany",
  "Ann Arbor",
  "Atlanta",
  "Bay Area",
  "Boca Raton",
  "Boston",
  "Cambridge",
  "Chicago",
  "Cologne",
  "Columbus",
  "DMV",
  "Denver",
  "Edinburgh",
  "Gaithersburg",
  "Hamburg",
  "Houston",
  "Indianapolis",
  "Limerick",
  "Ljubljana",
  "London",
  "Los Angeles",
  "Melbourne",
  "Miami",
  "Midwest",
  "Minneapolis",
  "Montreal",
  "Munich",
  "NRW",
  "New Haven",
  "New Jersey",
  "New York City",
  "Oklahoma City",
  "Oxford",
  "Philadelphia",
  "Phoenix",
  "Pittsburgh",
  "Portland",
  "Remote",
  "Research Triangle",
  "Rockville",
  "Salt Lake City",
  "San Diego",
  "Seattle",
  "Southeast",
  "St. Louis",
  "Syracuse",
  "Toronto",
  "Vancouver",
  "seattle",
]);

export const states = new Set([
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "FL",
  "GA",
  "IL",
  "IN",
  "MA",
  "MD",
  "MI",
  "MN",
  "MO",
  "NC",
  "NJ",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "TX",
  "UT",
  "WA",
]);
