import React, { useState, useEffect } from "react";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./styles/JobTitle.css";
import "../styles/common.css";

// Components
import SalaryTable from "../components/SalaryTable";
import { Header } from "../components/Header";
import CardLabel from "../components/CardLabel";
import AddSalaryButton from "../components/AddSalaryButton";
import { useJobTitleData } from "../hooks/useJobTitleData";
import { currencyFormatter } from "../components/Utils";
import { jobTitleLocations } from "../constants/locations";
import { UrlTojobTitleMapping } from "../constants/jobTitleConstants";

const BostonTestDataSimple = require("../testData/BostonTestDataSimple.json");
const Url = require("url-parse");
const _ = require("lodash");

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const chartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  aspectRatio: 1.2,
  responsive: true,
};

const donutOptions = {
  plugins: {
    legend: {
      position: "bottom",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const { dataset, dataIndex } = context;
          const data = dataset.data;
          const total = data.reduce((partialSum, a) => partialSum + a, 0);
          const currentValue = data[dataIndex];
          const percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return " " + currentValue + " (" + percentage + "%)";
        },
      },
    },
  },
  aspectRatio: 1,
  responsive: true,
};

const otherJobTitles = [
  "🧬 Associate Scientist",
  "🧪 Research Associate",
  "🧨 Senior Scientist",
  "💣 Director",
];

const getEducationData = (dataPoints) => {
  const results = {
    bachelors: 0,
    masters: 0,
    phd: 0,
    total: 0,
  };
  for (const dataPoint of dataPoints) {
    const educationLevel = dataPoint.education.toLowerCase();
    if (educationLevel.includes("bachelors")) {
      results.bachelors = results.bachelors + 1;
    } else if (educationLevel.includes("masters")) {
      results.masters = results.masters + 1;
    } else if (educationLevel.includes("phd")) {
      results.phd = results.phd + 1;
    } else {
      // Do not count other levels of education for now
    }
  }
  results.total = results.bachelors + results.masters + results.phd;
  return results;
};

export default function JobTitle() {
  const [jobTitle, setJobTitle] = useState("Scientist");
  const [location, setLocation] = useState("United States");

  useEffect(() => {
    const parsedUrl = new URL(window.location.href);
    const path = parsedUrl.pathname.split("/").at(-1); // get last part of path
    setJobTitle(path === "title" ? "scientist" : UrlTojobTitleMapping[path]);

    const query = parsedUrl.query;
    if (query) setLocation(query.location ? "US" : query.location);
  }, []);

  const jobTitleData = useJobTitleData({
    jobTitle: jobTitle,
    location: jobTitleLocations[location],
  }).sort((a, b) => {
    let x = a.timestamp.toLowerCase();
    let y = b.timestamp.toLowerCase();
    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
  });

  // Calculate Averages
  const avgSalary = currencyFormatter(
    _.round(
      _.mean(jobTitleData.map((salary) => parseInt(salary.totalCompensation))),
      -3
    )
  );

  const avgYOE = _.round(
    _.mean(jobTitleData.map((salary) => parseInt(salary.yoe))),
    1
  );

  const educationData = getEducationData(jobTitleData);

  const educationDonutData = {
    labels: ["Bachelors", "Masters", "PhD"],
    datasets: [
      {
        label: "Education Distribution",
        data: [
          educationData.bachelors,
          educationData.masters,
          educationData.phd,
        ],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const donutConfig = {
    type: "doughnut",
    data: educationDonutData,
  };

  // const salaryData = jobTitleData.filter((salary) => salary.company !== "-");
  // console.log(salaryData);

  const handleLocationChange = (event) => {
    setLocation(event.value);
  };

  return (
    <div class="">
      {/* Header */}
      <header class="text-3xl flex flex-wrap align-items-center">
        <Header title={jobTitle} location={location} />
      </header>

      {/* Main cards */}
      <div class="container-1100 section-1__background-color flex flex-column justify-content-between mb-3">
        <div class="grid">
          <div className="col-12 lg:col-7 flex mt-4 justify-content-between align-items-center">
            <Dropdown
              onChange={handleLocationChange}
              options={Object.keys(jobTitleLocations)}
              value={location}
            />
            <div>
              <AddSalaryButton />
            </div>
          </div>
          <div class="col-12 lg:col-7">
            {/* TODO: Pull out into separate component */}
            <Card>
              <div class="flex justify-content-around">
                <div class="flex flex-column ml-3">
                  <span class="text-3xl font-bold text-indigo-700">
                    {`US ${avgSalary}`}
                  </span>
                  <span class="text-center">Average salary</span>
                </div>
                <div class="flex flex-column mr-3 a">
                  <span class="text-3xl font-bold text-indigo-700 text-center">
                    {avgYOE}
                  </span>
                  <span class="text-center">Average years of experience</span>
                </div>
              </div>
              <Divider />

              <div class="flex flex-row justify-content-center">
                {/* <ResponsiveContainer width="100%" height="100%"> */}
                <BarChart
                  width={600}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey="pv" fill="#8884d8" />
                  <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
                {/* </ResponsiveContainer> */}
              </div>
            </Card>
          </div>
          <div class="col-12 lg:col-5">
            {/* TODO: Pull out into separate component */}
            <Card>
              <div class="mx-2">
                <div class="text-xl font-bold text-indigo-900 mb-3">
                  Education distribution
                </div>
                <div class="max-w-20rem mx-auto">
                  <Chart
                    type="doughnut"
                    data={educationDonutData}
                    options={donutOptions}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* Submitted Salaries Table */}
      <div class="container-1100 my-2">
        <div class="my-3">
          <CardLabel title="Recently Submitted Salaries" />
        </div>
        <SalaryTable data={jobTitleData} />
      </div>

      {/* Other Job Titles */}
      {/* TODO: Pull out into separate components */}
      <div class="container-1100 my-6">
        <div class="header-1__background-color p-6 border-round-xl">
          <div class="flex justify-content-between align-items-center">
            <div class="block lg:flex gap-1">
              <span class="text-xl font-bold text-indigo-900">{`Other `}</span>
              <span class="text-xl font-bold text-indigo-700">
                {`research and development `}
              </span>
              <span class="text-xl font-bold text-indigo-900">{`job titles`}</span>
            </div>
            <Button label="View all -->" size="small" text />
          </div>

          <div class="block lg:flex gap-3 my-3">
            {otherJobTitles.map((title, index) => (
              <Button
                className="bg-white text-indigo-900 hover:surface-ground my-1"
                label={title}
                size="medium"
                rounded
                text
                raised
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
