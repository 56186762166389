import "./styles/DisciplineHeader.css";

export const DisciplineHeader = () => {
  // Hard coded stuff:
  const discipline = "Research And Development";
  const location = "United States";

  return (
    <div className="header-container ">
      <div className="header__information m-3">
        <div class="text-7xl font-bold text-indigo-700">
          {discipline} &#128300;
        </div>
        <div class="text-5xl font-bold text-black-alpha-90 header__location">
          {location}
        </div>
        <div class=" text-m  text-black-alpha-90 header__description">
          Research and development, also known as R&D, is the process by which a
          company works to generate new knowledge that it might use to create
          new technology, products, services, or systems that it will either use
          or sell
        </div>
      </div>
    </div>
  );
};
