import { countries, cities, states } from "../constants/locations";

const TestDataJune2023 = require("../testData/TestDataJune2023.json");

export function useJobTitleData(jobTitleOptions) {
  const { jobTitle, location, discipline } = jobTitleOptions;

  const filteredData = TestDataJune2023.filter((entry) => {
    let match = false;
    if (countries.has(location)) {
      match = entry.country === location;
    } else if (cities.has(location)) {
      match = entry["city"] === location;
    } else if (states.has(location)) {
      match = entry.state === location;
    }
    return entry.jobTitle === jobTitle && match;
  });

  return filteredData;
}
