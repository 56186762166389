import clsx from "clsx";
import "./styles/Header.css";
import "../styles/common.css";

export const Header = ({ title, location, median, averages }) => {
  // Hard coded stuff
  // title = "Scientist";
  // location = "San Francisco Bay Area, USA";
  console.log(location);
  median = "116,000";
  averages = [{ amount: "80" }, { amount: "142" }, { amount: "255.3" }];

  // Should add these into constants:
  const percentiles = ["25TH", "75TH", "90TH"];
  const percentileColors = ["white", "light", "dark"];

  return (
    <div className="header-container">
      <div className="container-1100 header__information">
        <div class="text-7xl font-bold text-indigo-700">&#128300;</div>
        <div class="text-7xl font-bold text-indigo-700">{title}</div>
        <div class="text-2xl font-bold text-indigo-900"> {location} </div>
      </div>
      {/* Archive percentiles for now */}
      {/* <div className="header__averages">
          {averages.map((average, index) => {
            const position = index;
            const { amount } = average;
            return (
              <div className="header__average">
                <div
                  className={clsx("average__bar", percentileColors[position])}
                ></div>
                <div className="average__amount">US ${amount}K</div>
                <div className="average__percentile">
                  {percentiles[position]}
                </div>
              </div>
            );
          })}
        </div> */}

      {/* <div className="header__buttons">
          <button
            className="button__contribute"
            onClick={console.log("CLICK MEH")}
          >
            Contribute To Salary &#128170;
          </button>
          <button className="button__jobs" onClick={console.log("CLICK MEH")}>
            View Jobs
          </button>
        </div> */}

      {/* <div className="median-salary__container">
        <div className="median-salary__title">Median Salary</div>
        <div className="header__salary">US {median}</div>
      </div> */}
    </div>
  );
};
