import React from "react";
import { DisciplineHeader } from "../components/DisciplineHeader";
import { JobTitleDeck } from "../components/TitlesTable";
import "./styles/Discipline.css";

export function Discipline() {
  return (
    <div>
      <DisciplineHeader />
      <div class="titles-container">
        <div class="text-3xl m-3 font-bold">Explore By Job Titles</div>
        <JobTitleDeck />
      </div>
    </div>
  );
}
