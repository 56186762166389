export const companyFields = {
  company: {
    tag: "company",
    title: "Company",
    required: true,
    type: "text",
  },
  companyTitle: {
    tag: "companyTitle",
    title: "Company Title",
    required: true,
    type: "text",
  },
  companyLevel: {
    tag: "companyLevel",
    title: "Level at company",
    type: "text",
  },
  companyTenure: {
    tag: "companyTenure",
    title: "Years at company",
    required: true,
    type: "Number",
  },
  country: {
    tag: "country",
    title: "Country",
    required: true,
    type: "text",
  },
  location: {
    tag: "location",
    title: "Location",
    required: true,
    type: "text",
  },
  workArrangement: {
    tag: "workArrangement",
    title: "Office/Remote",
    required: false,
    type: "dropdown",
    options: [
      { value: "office", label: "Office" },
      { value: "remote", label: "Remote" },
      { value: "hybrid", label: "Hybrid" },
    ],
  },
};

export const educationDiscipline = {
  education: {
    fieldTag: "education",
    fieldTitle: "Education Level",
    required: true,
    type: "dropdown",
    options: [
      { value: "diploma", label: "Diploma" },
      { value: "bachelors", label: "Bachelors" },
      { value: "masters", label: "Masters" },
      { value: "phd", label: "PhD" },
    ],
  },
  discipline: {
    fieldTag: "discipline",
    fieldTitle: "Discipline",
    required: true,
    type: "dropdown",
    options: [
      { value: "rD", label: "Research & Development" },
      { value: "clinical", label: "Clinical" },
      { value: "mP", label: "Manufacturing & Production" },
      { value: "qa", label: "Quality Assurance" },
      { value: "regulatory", label: "Regulatory" },
      { value: "sales", label: "Sales" },
      { value: "marketing", label: "Marketing" },
      { value: "hr", label: "Human Resources" },
      { value: "finance", label: "Finance" },
      { value: "it", label: "Information Technology" },
      { value: "other", label: "Other" },
    ],
  },
  yoe: {
    fieldTag: "yoe",
    fieldTitle: "Years Of Experience",
    required: true,
    type: "number",
  },
  yearsAtCompany: {
    fieldTag: "yearsAtCompany",
    fieldTitle: "Years At Company",
    required: false,
    type: "number",
  },
  fieldOfStudy: {
    fieldTag: "yearsOfStudy",
    fieldTitle: "Field Of Study",
    required: false,
    type: "text",
  },
};

export const salaryInformation = {
  salaryBase: {
    fieldTag: "salaryBase",
    fieldTitle: "Annual Base Salary",
    required: true,
    type: "number",
  },
  salaryBonus: {
    fieldTag: "salaryBonus",
    fieldTitle: "Salary Target Bonus",
    required: false,
    type: "number",
    defaultValue: 0,
  },
  salaryEquity: {
    fieldTag: "salaryEquity",
    fieldTitle: "Salary Equity",
    required: false,
    type: "number",
    defaultValue: 0,
  },
  salarySignOn: {
    fieldTag: "salarySignOn",
    fieldTitle: "Sign On Bonus",
    required: false,
    type: "number",
    defaultValue: 0,
  },
};
