import { useNavigate } from "react-router-dom";

import "./styles/TitlesTable.css";
import { JobTitleToURLMapping } from "../constants/jobTitleConstants";

// export const JobCard = ({icon, }) = {};

export const JobTitleDeck = ({ disciplineJobList }) => {
  const navigate = useNavigate();
  // Fake Data -> Should be what the incoming data looks like
  disciplineJobList = [
    {
      icon: "🧪",
      title: "Research Associate",
      medianSalary: "53,000",
    },
    { icon: "🧫", title: "Senior Research Associate", medianSalary: "83,000" },
    { icon: "🧬", title: "Associate Scientist", medianSalary: "90,000" },
    { icon: "💉", title: "Senior Associate Scientist", medianSalary: "92,000" },
    { icon: "🔬", title: "Scientist", medianSalary: "102,000" },
    { icon: "💊", title: "Senior Scientist", medianSalary: "128,000" },
  ];

  return (
    <div class="job-deck__container flex flex-row flex-wrap card-container  ">
      {disciplineJobList.map((discipline) => {
        const { icon, title, medianSalary } = discipline;
        return (
          <div
            onClick={() => {
              console.log(title);
              navigate(`/title/${JobTitleToURLMapping[title]}`);
              window.scrollTo(0, 0);
            }}
            class="discipline-card flex flex-column align-items-center 
            justify-content-center w-22rem h-12rem text-black border-round-2xl m-3 cursor-pointer hover:shadow-4"
          >
            <div class="discipline__icon font-8xl m-2"> {icon ? icon : ""}</div>
            <div class="discipline__title text-2xl">
              {title ? title : "Unknown Title"}
            </div>
            <div class="discipline__salary font-bold text-xl m-1">
              ${medianSalary ? medianSalary : "Not Enough Data"}
            </div>
            <div class=" discipline__median-salary text-xs ">
              Median Salary{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
};
