import React from "react";
import { Button } from "primereact/button";

export default function AddSalaryButton() {
  return (
    <Button
      label="Contribute your salary"
      size="small"
      onClick={() => {
        window.location.href = "/contribute";
      }}
    />
  );
}
