import "./styles/ContributionForm.css";
import "./styles/Header.css";
import "../styles/common.css";
import { Controller, useForm } from "react-hook-form";
import { useRef, useState } from "react";
import axios from "axios";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import {
  educationDiscipline,
  companyFields,
  salaryInformation,
} from "../constants/contributionFormFields";
import { companies } from "../constants/companies";

const defaultValues = {
  company: "",
  companyTitle: "",
  companyLevel: "",
  discipline: "",
  location: "",
  workArrangement: "",
  yearsAtCompany: "",
  yoe: "",
  education: "",
  fieldOfStudy: "",
  salaryBase: "",
  salarySignOn: "",
  salaryBonus: "",
  salaryEquity: "",
};

export const ContributionForm = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });
  const toast = useRef(null);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Submission Successful",
      detail: "Your salary was submitted succcesfully ",
    });
  };

  const [companyName, setCompanyName] = useState("");
  const [companySuggestions, setCompanySuggestions] = useState(companies);
  const companySearch = (event) => {
    setCompanySuggestions(
      companies.filter((item) => item.includes(event.query))
    );
  };

  const [locationQuery, setLocationQuery] = useState("");
  const [locationResponse, setLocationResponse] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const locationSearch = (event) => {
    axios
      .get(`https://api.biosci.fyi/search/cities?location=${event.query}`, {
        crossDomain: true,
      })
      .then((response) => {
        const locationDescriptions = response.data.map((l) => l.description);
        setLocationSuggestions(locationDescriptions);
        const locationObjectMap = {};
        response.data.forEach((l) => (locationObjectMap[l.description] = l));
        setLocationResponse(locationObjectMap);
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const onSubmit = (data) => {
    // setShowMessage(true);
    const payload = { ...data };

    // format location
    const locationDescription = data.location;
    payload.country = locationResponse[locationDescription].country.trim();
    payload.location = locationResponse[locationDescription].location.trim();

    // Calculate total compensation
    payload.salaryTotal =
      data.salaryBase +
      data.salaryBonus +
      data.salaryEquity +
      data.salarySignOn;

    // Save submission
    axios
      .post(
        "https://api.biosci.fyi/salary/submit",
        { data: payload },
        {
          crossdomain: true,
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
    reset(); // Uncomment on real thing so people can submit multiple salaries.
    showSuccess();
  };

  return (
    <div>
      <div className="contribute-salary__container">
        <Toast ref={toast} position="top-center"></Toast>
        <div className=" contribute-header-container flex flex-column align-items-center justify-content-center ">
          <div className=" text-7xl font-bold text-indigo-700">
            <span className="contribute-header__title">Submit Your Salary</span>
          </div>
          <div className="text-2xl font-bold text-indigo-900">
            <span className="contribute-header__subtitle">
              Completely Anonymously
            </span>
          </div>
        </div>
        <div className="salary-form__container">
          <div className="form-header__container">
            <div className="font-bold text-lg company-info__title my-3">
              Contribute your salary to help millions
            </div>
            <div className=" my-3">
              Your salary will be completely anonymous. Thank you for helping us
              on our mission for Biotech pay transparency 😁
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="form-section__container">
              <div className="font-bold underline text-lg company-info__title my-3">
                Company & Title Information
              </div>

              {/* Company Name */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {companyFields.company.title}
                  {companyFields.company.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={companyFields.company.tag}
                  control={control}
                  rules={{ required: "Company name is required." }}
                  defaultValue=""
                  render={({ field }) => (
                    <AutoComplete
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      completeMethod={companySearch}
                      id={field.name}
                      onChange={(e) => setCompanyName(e.value)}
                      placeholder="Genentech"
                      suggestions={companySuggestions}
                      value={companyName}
                      {...field}
                    />
                  )}
                />
                {getFormErrorMessage("company")}
              </div>

              {/* Company Title */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {companyFields.companyTitle.title}
                  {companyFields.companyTitle.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={companyFields.companyTitle.tag}
                  control={control}
                  rules={{ required: "Company title is required." }}
                  render={({ field }) => (
                    <AutoComplete
                      id={field.name}
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      placeholder="Scientist"
                    />
                  )}
                />
                {getFormErrorMessage("companyTitle")}
              </div>

              {/* Company Level */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {companyFields.companyLevel.title}
                  {companyFields.companyLevel.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={companyFields.companyLevel.tag}
                  control={control}
                  rules={{ required: "Company level is required." }}
                  render={({ field }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      placeholder="PDS2"
                    />
                  )}
                />
              </div>

              {/* Company Discipline */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {educationDiscipline.discipline.fieldTitle}
                  {educationDiscipline.discipline.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={educationDiscipline.discipline.fieldTag}
                  control={control}
                  rules={{ required: "Discipline is required." }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id="discipline"
                      value={field.value}
                      optionLabel={field.label}
                      placeholder="Select a discipline"
                      options={educationDiscipline.discipline.options}
                      focusInputRef={field.ref}
                      onChange={(e) => field.onChange(e.value)}
                      className="contribute-form-input p-inputtext-sm"
                    />
                  )}
                />
                {getFormErrorMessage("discipline")}
              </div>

              {/* Company Location */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {companyFields.location.title}
                  {companyFields.location.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={companyFields.location.tag}
                  control={control}
                  render={({ field }) => (
                    <AutoComplete
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      completeMethod={locationSearch}
                      forceSelection
                      id={field.name}
                      onChange={(e) => {
                        setValue("location", e.value);
                        setLocationQuery(e.value);
                      }}
                      suggestions={locationSuggestions}
                      value={locationQuery}
                    />
                  )}
                />
              </div>

              {/* Company Work Arrangement */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {companyFields.workArrangement.title}
                  {companyFields.workArrangement.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={companyFields.workArrangement.tag}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id="workArrangement"
                      value={field.value}
                      optionLabel={field.label}
                      placeholder="Select a Work Arrangement "
                      options={companyFields.workArrangement.options}
                      focusInputRef={field.ref}
                      onChange={(e) => field.onChange(e.value)}
                      className="contribute-form-input p-inputtext-sm"
                    />
                  )}
                />
              </div>
            </div>

            <div className="form-section__container">
              <div className="font-bold underline text-lg company-info__title my-3">
                Education & Work Experience
              </div>

              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {educationDiscipline.yearsAtCompany.fieldTitle}
                  {educationDiscipline.yearsAtCompany.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="yearsAtCompany"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      defaultValue="0"
                      id="yearsAtCompany"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>

              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {educationDiscipline.yoe.fieldTitle}
                  {educationDiscipline.yoe.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="yoe"
                  control={control}
                  rules={{ required: "Years of experience is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id="yoe"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("yoe")}
              </div>

              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {educationDiscipline.education.fieldTitle}
                  {educationDiscipline.education.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name={educationDiscipline.education.fieldTag}
                  control={control}
                  rules={{ required: "Education level is required." }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id="education"
                      value={field.value}
                      optionLabel={field.label}
                      placeholder="Select an education level"
                      options={educationDiscipline.education.options}
                      focusInputRef={field.ref}
                      onChange={(e) => field.onChange(e.value)}
                      className="contribute-form-input p-inputtext-sm"
                    />
                  )}
                />
                {getFormErrorMessage("education")}
              </div>

              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {educationDiscipline.fieldOfStudy.fieldTitle}
                  {educationDiscipline.fieldOfStudy.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="fieldOfStudy"
                  control={control}
                  render={({ field }) => (
                    <InputText
                      id="fieldOfStudy"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-section__container">
              <div className="font-bold underline text-lg company-info__title my-3">
                Salary Information
              </div>

              {/* Base Salary */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {salaryInformation.salaryBase.fieldTitle}
                  {salaryInformation.salaryBase.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="salaryBase"
                  control={control}
                  rules={{ required: "Annual base salary  is required." }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      mode="currency"
                      currency="USD"
                      maxFractionDigits={0}
                      defaultValue="0"
                      id="salaryBase"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
                {getFormErrorMessage("salaryBase")}
              </div>

              {/* Salary Sign On*/}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {salaryInformation.salarySignOn.fieldTitle}
                  {salaryInformation.salarySignOn.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="salarySignOn"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      defaultValue="0"
                      mode="currency"
                      currency="USD"
                      maxFractionDigits={0}
                      id="salarySignOn"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>

              {/* Salary Bonus */}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {salaryInformation.salaryBonus.fieldTitle}
                  {salaryInformation.salaryBonus.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="salaryBonus"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      defaultValue="0"
                      mode="currency"
                      currency="USD"
                      maxFractionDigits={0}
                      id="salaryBonus"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>

              {/* Salary Equity =*/}
              <div className="my-3 p-fluid">
                <div className="text-sm font-semibold my-1">
                  {salaryInformation.salaryEquity.fieldTitle}
                  {salaryInformation.salaryEquity.required && (
                    <span className="required-asterisk"> *</span>
                  )}
                </div>
                <Controller
                  name="salaryEquity"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      defaultValue="0"
                      mode="currency"
                      currency="USD"
                      maxFractionDigits={0}
                      id="salaryEquity"
                      {...field}
                      autoFocus
                      className="contribute-form-input p-inputtext-sm"
                      min={0}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="submit-container flex flex-row ">
              <input
                className="ml-7 mt-3 submit-button pr-4 pl-4  font-semibold"
                type="submit"
              ></input>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
