import React from "react";
import { Menubar } from "primereact/menubar";

export default function Navigation() {
  const navList = [
    {
      label: "Home",
      icon: "pi pi-fw pi-home",
      command: () => {
        window.location.href = "/";
      },
    },
    {
      label: "Locations",
      icon: "pi pi-fw pi-calendar",
      command: () => {
        window.location.href = "/locations";
      },
    },
    {
      label: "Job Title",
      icon: "pi pi-fw pi-calculator",
      command: () => {
        window.location.href = "/title";
      },
    },
    {
      label: "Discipline",
      icon: "pi pi-fw pi-briefcase",
      command: () => {
        window.location.href = "/discipline";
      },
    },
  ];

  return (
    <div>
      <header>
        <nav>
          <Menubar model={navList} />
        </nav>
      </header>
    </div>
  );
}
