export const companies = [
  "3M",
  "4D Molecular Therapeutics",
  "A-Alpha Bio",
  "A2 Biotherapeutics ",
  "ACROBioSystems",
  "ADARx Pharmaceuticals",
  "AN2 Therapeutics",
  "ANI Pharmaceuticals",
  "ATCC",
  "AbbVie",
  "Acrivon Therapeutics",
  "Actuate Therapeutics",
  "Acumen Pharmaceuticals",
  "Adaptive Biotechnologies",
  "Adicet Bio",
  "Affinia Therapeutics",
  "Alkahest",
  "Allen Institute",
  "Alumis",
  "Ambrx",
  "Amgen",
  "AnaptysBio",
  "Anavex Life Sciences",
  "Andelyn Biosciences",
  "Annexon",
  "Ansun Biopharma",
  "Anthos Therapeutics",
  "Apollomics",
  "Arbor Biotechnologies",
  "Arcturus Therapeutics",
  "Arcus Biosciences",
  "Arvinas",
  "Aspen Neuroscience",
  "Autobahn Therapeutics",
  "Autonomous Therapeutics",
  "Avero Diagnostics",
  "Avid Bioservices",
  "Avidity Biosciences",
  "Ayala Pharmaceuticals",
  "Ayuvis Research",
  "BIOMILQ",
  "BIOTRONIK",
  "BRAINbox Solutions",
  "Barinthus Biotherapeutics",
  "Bend Bioscience",
  "BigHat Biosciences",
  "BioMarin Pharmaceutical",
  "BioSig Technologies",
  "Biohaven",
  "Bionical Emas",
  "Biopact",
  "Birch Biosciences",
  "Black Diamond Therapeutics",
  "BlueRock Therapeutics",
  "Boehringer Ingelheim Fremont",
  "Bolt Biotherapeutics",
  "Bristol Myers Squibb Company",
  "CARsgen Therapeutics",
  "CMIC Inc",
  "CSL Behring",
  "CSL Seqirus",
  "Cabaletta Bio",
  "Cajal Neuroscience",
  "California Life Sciences Association",
  "Capsida Biotherapeutics",
  "Carisma Therapeutics",
  "Carmot Therapeutics",
  "Cassava Sciences",
  "Catalyst Clinical Research",
  "Catawba Research",
  "Cell Microsystems",
  "Centerline Biomedical",
  "Chimerix",
  "Civica Rx",
  "Clinical Ink",
  "Codexis",
  "Coherus BioSciences",
  "Contraline",
  "Corcept Therapeutics",
  "Cour Pharmaceuticals",
  "Curis",
  "Cyclacel Pharmaceuticals",
  "DNA Script",
  "Daiichi Sankyo",
  "Dava Oncology",
  "Day One Biopharmaceuticals",
  "Demeetra AgBio",
  "Dermavant Sciences",
  "Dicerna Pharmaceuticals",
  "Dignify Therapeutics",
  "Drexel University",
  "Dyne Therapeutics",
  "Editas Medicine",
  "Eikon Therapeutics",
  "Eitr Biologics",
  "Eledon Pharmaceuticals",
  "Element Biosciences",
  "Eli Lilly and Company",
  "Elicio Therapeutics",
  "Elligo Health Research",
  "Emergent BioSolutions",
  "Entrada Therapeutics",
  "Equillium",
  "Evolve Biologics",
  "Exelixis",
  "Fate Therapeutics",
  "Food and Drug Administration (FDA)",
  "Foresee Pharmaceuticals",
  "Formation Bio",
  "Frederick National Laboratory for Cancer Research",
  "Freenome",
  "GRAIL",
  "Gemelli Biotech",
  "Genentech",
  "Genprex",
  "Gilead Sciences",
  "Gladstone Institutes",
  "GoDX, INC",
  "HUYABIO International",
  "Hope Biosciences",
  "IDEAYA Biosciences",
  "Idorsia Pharmaceuticals US",
  "Imbed Biosciences",
  "Immatics",
  "Immunic Therapeutics",
  "Immuto Scientific",
  "InVitro Cell Research",
  "Inceptor Bio",
  "Incyclix Bio",
  "InduPro",
  "Inhalon Biopharma",
  "Inotiv",
  "Inozyme Pharma",
  "Intact Genomics",
  "Intellia Therapeutics",
  "Intra-Cellular Therapies",
  "Inventprise",
  "Ipsen Biopharmaceuticals",
  "Karius",
  "Karuna Therapeutics ",
  "Kate Therapeutics",
  "KeVIRx",
  "Keliomics",
  "Kerecis",
  "Kolon Tissuegene",
  "Kumquat Biosciences",
  "Lexitas",
  "Life Edit Therapeutics",
  "Longboard Pharmaceuticals",
  "Lonza",
  "Lumos Pharma",
  "Lundbeck",
  "Lupagen",
  "Lyra Therapeutics",
  "MAIA Biotechnology",
  "MBX Biosciences",
  "MOMA Therapeutics",
  "MacroGenics",
  "Mammoth Biosciences",
  "Maze Therapeutics",
  "Merck & Co.",
  "Metabolon",
  "Millipore Sigma",
  "Mission Bio ",
  "Moderna",
  "Molecular Templates",
  "Monod Bio",
  "NImmune Biopharma",
  "Nabsys",
  "Nektar Therapeutics",
  "NemaLife Inc",
  "Neogene Therapeutics",
  "Neuraly",
  "NeuroNascent",
  "New England BioLabs",
  "NexImmune",
  "NiKang Therapeutics ",
  "Nicoya",
  "Nimble Therapeutics",
  "Nitto BioPharma",
  "Notch Therapeutics",
  "Novo Nordisk",
  "Nucleai",
  "Nurix Therapeutics",
  "Obsidian Therapeutics",
  "Olema Oncology",
  "Omega Therapeutics",
  "Omeros Corporation",
  "OmniAb",
  "OncoBay Clinical",
  "Organon",
  "Ossium Health",
  "Outpace",
  "PDX Pharmaceuticals",
  "POINT Biopharma",
  "PacBio",
  "Pace Labs",
  "Palleon Pharmaceuticals",
  "Panbela Therapeutics",
  "Parse Biosciences",
  "Pathos",
  "PharmaLogics Recruiting",
  "Phlow",
  "Pionyr Immunotherapeutics",
  "Polaris Pharmaceuticals",
  "Precision BioSciences",
  "Processa Pharmaceuticals",
  "ProfoundBio",
  "Propella Therapeutics",
  "PsychoGenics",
  "QPS",
  "Quoin Pharmaceuticals",
  "Rain Oncology",
  "RareCyte",
  "ReCode Therapeutics",
  "Rebiotix",
  "RefleXion",
  "Regeneron Pharmaceuticals",
  "Remedy Plan Therapeutics",
  "Renibus Therapeutics",
  "Rentschler Biopharma",
  "Resilience",
  "Revalesio",
  "Rhythm Pharmaceuticals",
  "SIGA Technologies",
  "Sage Therapeutics",
  "Salarius Pharmaceuticals",
  "Sana Biotechnology",
  "Sangamo Therapeutics",
  "Santen",
  "Scilex Holding Company",
  "ScitoVation",
  "Scorpius Biomanufacturing",
  "Shuttle Pharmaceuticals",
  "Signature Science",
  "Spark Biomedical",
  "Starton Therapeutics",
  "Surrozen",
  "Sutro Biopharma",
  "Syner-G BioPharma Group",
  "Taconic Biosciences",
  "Takeda",
  "Telesis Bio",
  "Terray Therapeutics",
  "Thermo Fisher Scientific",
  "Theseus Pharmaceuticals",
  "Tiamat Sciences",
  "Tr1x Bio",
  "Tris Pharma",
  "Tune Therapeutics",
  "Tvardi Therapeutics",
  "United States Pharmacopeia",
  "Variant Bio",
  "Vaxcyte",
  "Visterra",
  "Volta Medical",
  "Voyager Therapeutics",
  "X-Chem",
  "Xilis",
  "Xsome Biotech",
  "Zai Lab (US) LLC",
  "Zephyr AI",
  "enVVeno Medical",
];
